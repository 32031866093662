var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "home" }, [
    _c(
      "div",
      { staticClass: "filter" },
      [
        _c("span", { class: [_vm.lang] }, [_vm._v(_vm._s(_vm.$t("Status")))]),
        _c(
          "a-select",
          {
            staticClass: "select",
            class: [_vm.lang],
            staticStyle: { width: "360px" },
            attrs: { placeholder: "Please select status", size: "large" },
            on: { change: _vm.handleChange },
            model: {
              value: _vm.status,
              callback: function($$v) {
                _vm.status = $$v
              },
              expression: "status"
            }
          },
          [
            _c("a-select-option", { class: [_vm.lang], attrs: { value: "" } }, [
              _vm._v(" " + _vm._s(_vm.$t("All")))
            ]),
            _c(
              "a-select-option",
              { class: [_vm.lang], attrs: { value: "0" } },
              [_vm._v(" " + _vm._s(_vm.$t("PendingConnection")))]
            ),
            _c(
              "a-select-option",
              { class: [_vm.lang], attrs: { value: "1" } },
              [_vm._v(" " + _vm._s(_vm.$t("ConnectionRequestSent")) + " ")]
            ),
            _c(
              "a-select-option",
              { class: [_vm.lang], attrs: { value: "2" } },
              [_vm._v(" " + _vm._s(_vm.$t("ConnectionApproved")) + " ")]
            ),
            _c(
              "a-select-option",
              { class: [_vm.lang], attrs: { value: "4" } },
              [_vm._v(" " + _vm._s(_vm.$t("ResponseReceived")) + " ")]
            ),
            _c(
              "a-select-option",
              { class: [_vm.lang], attrs: { value: "5" } },
              [
                _vm._v(
                  " " + _vm._s(_vm.$t("LeadContactInformationObtained")) + " "
                )
              ]
            )
          ],
          1
        )
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "wrap-table table-content-wrapper" },
      [
        _c("a-table", {
          class: { arTable: _vm.lang == "AR" ? true : false },
          attrs: {
            scroll: {
              x: 1120,
              y: _vm.tableScrollY
            },
            size: "middle",
            columns: _vm.columns,
            rowKey: function(record, index) {
              return index
            },
            "data-source": _vm.tableData,
            pagination: false,
            loading: _vm.loading,
            customRow: function(record) {
              return {
                on: {
                  click: function() {
                    return _vm.goDetail(record)
                  }
                }
              }
            },
            "row-class-name": function(_record, index) {
              return index % 2 !== 1 ? "table-bg" : null
            }
          },
          scopedSlots: _vm._u([
            {
              key: "name",
              fn: function(text, record) {
                return [
                  _c("div", { staticClass: "wrap-name" }, [
                    _c("div", { staticClass: "wrap-img" }, [
                      record.head_img
                        ? _c("img", {
                            class: { rtl: _vm.lang == "AR" ? true : false },
                            attrs: { src: record.head_img }
                          })
                        : _c("img", {
                            class: { rtl: _vm.lang == "AR" ? true : false },
                            attrs: { src: require("@/assets/img/avat.png") }
                          })
                    ]),
                    _c(
                      "span",
                      {
                        staticClass: "skip",
                        on: {
                          click: function($event) {
                            return _vm.skip(record)
                          }
                        }
                      },
                      [_vm._v(_vm._s(record.name))]
                    )
                  ])
                ]
              }
            },
            {
              key: "action",
              fn: function(text, record) {
                return [
                  !_vm.showPageFooter
                    ? _c(
                        "span",
                        {
                          staticClass: "statusBtn",
                          class: [_vm.lang],
                          on: { click: _vm.upgreadHandler }
                        },
                        [_vm._v(_vm._s(_vm.$t("AutomaticConnections")))]
                      )
                    : _vm._e(),
                  record.status == 1
                    ? _c(
                        "span",
                        {
                          staticClass: "statusText",
                          staticStyle: { color: "#666" }
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.$t("ConnectionRequestSent")) + " "
                          )
                        ]
                      )
                    : _vm._e(),
                  record.status == 2
                    ? _c(
                        "span",
                        {
                          staticClass: "statusText",
                          staticStyle: { color: "#666" }
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.$t("ConnectionApproved")) + " "
                          )
                        ]
                      )
                    : _vm._e(),
                  record.status == 4
                    ? _c(
                        "span",
                        {
                          staticClass: "statusText",
                          staticStyle: { color: "#666" }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("ResponseReceived")) + " ")]
                      )
                    : _vm._e(),
                  record.status == 5
                    ? _c(
                        "span",
                        {
                          staticClass: "statusText",
                          staticStyle: { color: "#666" }
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("LeadContactInformationObtained")) +
                              " "
                          )
                        ]
                      )
                    : _vm._e()
                ]
              }
            }
          ])
        }),
        _vm.showPageFooter
          ? _c(
              "div",
              [
                _c("app-footer", {
                  attrs: {
                    current: _vm.page,
                    pageSize: _vm.pageSize,
                    total: _vm.total
                  },
                  on: {
                    onPageChange: _vm.onPageChange,
                    onPageSizeChange: _vm.onPageSizeChange
                  }
                })
              ],
              1
            )
          : _vm._e()
      ],
      1
    ),
    !_vm.showPageFooter
      ? _c("div", { staticClass: "planGuide" }, [
          _c("div", { staticClass: "left" }, [
            _c("div", { staticClass: "tit" }, [
              _vm._v(_vm._s(_vm.$t("viewComplteList")))
            ]),
            _c("div", { staticClass: "piclist" }, [
              _c("ul", [
                _c("li", { style: { zIndex: 1, left: 0 } }, [
                  _c("img", {
                    attrs: { src: require("@/assets/img/avat.png") }
                  })
                ]),
                _c("li", { style: { zIndex: 1, left: "-15px" } }, [
                  _c("img", {
                    attrs: { src: require("@/assets/img/avat.png") }
                  })
                ]),
                _c("li", { style: { zIndex: 1, left: "-30px" } }, [
                  _c("img", {
                    attrs: { src: require("@/assets/img/avat.png") }
                  })
                ]),
                _c("li", { style: { zIndex: 1, left: "-45px" } }, [
                  _c("img", {
                    attrs: { src: require("@/assets/img/avat.png") }
                  })
                ])
              ]),
              _c("span", { staticClass: "dotted" }, [_vm._v("···")])
            ]),
            _c("p", { class: [_vm.lang] }, [
              _vm._v(
                " " +
                  _vm._s(_vm.$t("CustomersAwaiting").replace("xxx", "")) +
                  " "
              )
            ])
          ]),
          _c("div", { staticClass: "right" }, [
            _c("div", { staticClass: "off" }, [
              _vm._v(_vm._s(_vm.$t("SaveNow")))
            ]),
            _c("div", { staticClass: "tit" }),
            _c("ul", [
              _c("li", [
                _c("div", { staticClass: "time" }, [_vm._v(_vm._s(_vm.days))]),
                _c("div", { staticClass: "flag" }, [
                  _vm._v(_vm._s(_vm.$t("day")))
                ])
              ]),
              _c("li", [
                _c("div", { staticClass: "time" }, [_vm._v(_vm._s(_vm.hours))]),
                _c("div", { staticClass: "flag" }, [
                  _vm._v(_vm._s(_vm.$t("Hrs")))
                ])
              ]),
              _c("li", [
                _c("div", { staticClass: "time" }, [
                  _vm._v(_vm._s(_vm.minutes))
                ]),
                _c("div", { staticClass: "flag" }, [
                  _vm._v(_vm._s(_vm.$t("Mins")))
                ]),
                _c("div", { staticClass: "split" }, [_vm._v(":")])
              ]),
              _c("li", [
                _c("div", { staticClass: "time" }, [
                  _vm._v(_vm._s(_vm.seconds))
                ]),
                _c("div", { staticClass: "flag" }, [
                  _vm._v(_vm._s(_vm.$t("Secs")))
                ]),
                _c("div", { staticClass: "split" }, [_vm._v(":")])
              ])
            ]),
            _c(
              "div",
              { staticClass: "btn", on: { click: _vm.upgreadHandler } },
              [_vm._v(_vm._s(_vm.$t("updateBtn")))]
            )
          ])
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }